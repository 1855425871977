var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "help-icon-div",
      class: _vm.$mq != "lg" ? "help-icon-div-mobile" : "",
    },
    [
      _c(
        "button",
        {
          staticClass: "btn btn-primary learn-more-button",
          on: {
            click: function ($event) {
              return _vm.$emit("onclick", $event)
            },
          },
        },
        [
          _c("feather-icon", {
            staticClass: "learn-more-button-icon",
            attrs: { type: "book-open", width: "25", height: "25" },
          }),
          _c("p", { staticClass: "learn-more-button-text" }, [
            _vm._v("\n      Learn More\n    "),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }