<template>
  <div :class="$mq != 'lg' ? 'help-icon-div-mobile' : ''" class="help-icon-div">
    <button class="btn btn-primary learn-more-button" @click="$emit('onclick', $event)">
      <feather-icon type="book-open" width="25" height="25" class="learn-more-button-icon" />
      <p class="learn-more-button-text">
        Learn More
      </p>
    </button>
  </div>
</template>


<script>

import FeatherIcon from '@/components/shared/FeatherIcon'

export default {
  name: 'LearnMoreButton',
  components: {
    FeatherIcon,
  },
}
</script>



<style lang="scss" scoped>
.help-icon-div {
  position: fixed;
  bottom: 30px;
  right: 30px;
}
.help-icon-div-mobile {
  bottom: 60px !important;
  right: 15px !important;
}
.learn-more-button {
  border-radius: 24px;
  font-size: 14px;
  border: none;
  outline: none;
  white-space: nowrap;
  padding: 10px 18px;
  transition: all 0.8s ease-in-out 0s;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  overflow: hidden;
}
.learn-more-button:hover {
  .learn-more-button-text {
    display:inline;
  }
}
.learn-more-button-icon {
  display: inline-block;
  flex-shrink: 0;
  line-height: 1;
  width: 24px;
  height: 24px;
}
.learn-more-button-text {
  font-size: 1.14286em;
  font-style: inherit;
  line-height: 1.25;
  letter-spacing: -0.006em;
  color: rgb(255, 255, 255);
  font-weight: 600;
  display: none;
  margin: 0px;
  padding-left: 6px;
  padding-right: 8px;
}
</style>
